<template>
  <v-container>
    <GameWikiPageExternalLinkDialog
        :externalLink="externalLink"
        :dialog.sync="isShowExternalLinkDialog"
    ></GameWikiPageExternalLinkDialog>
    <div>
      <div v-if="gamePushWikiPageLanguage === 'de'">
        <link rel="stylesheet"
              href="https://de.wikipedia.org/w/load.php?lang=de&amp;modules=ext.cite.styles%7Cext.flaggedRevs.basic%2Cicons%7Cext.uls.interlanguage%7Cext.visualEditor.desktopArticleTarget.noscript%7Cext.wikimediaBadges%7Cmediawiki.widgets.styles%7Coojs-ui-core.icons%2Cstyles%7Coojs-ui.styles.indicators%7Cskins.vector.styles.legacy%7Cwikibase.client.init&amp;only=styles&amp;skin=vector"/>
        <link rel="stylesheet"
              href="https://de.wikipedia.org/w/load.php?lang=de&amp;modules=site.styles&amp;only=styles&amp;skin=vector"/>
        <link rel="stylesheet"
              href="https://de.wikipedia.org/w/load.php?lang=de&modules=site.styles&only=styles&skin=vector">
      </div>
      <div v-else-if="gamePushWikiPageLanguage === 'en'">
        <link rel="stylesheet"
              href="https://en.wikipedia.org/w/load.php?lang=en&amp;modules=ext.cite.styles%7Cext.flaggedRevs.basic%2Cicons%7Cext.uls.interlanguage%7Cext.visualEditor.desktopArticleTarget.noscript%7Cext.wikimediaBadges%7Cmediawiki.widgets.styles%7Coojs-ui-core.icons%2Cstyles%7Coojs-ui.styles.indicators%7Cskins.vector.styles.legacy%7Cwikibase.client.init&amp;only=styles&amp;skin=vector"/>
        <link rel="stylesheet"
              href="https://en.wikipedia.org/w/load.php?lang=en&amp;modules=site.styles&amp;only=styles&amp;skin=vector"/>
        <link rel="stylesheet"
              href="https://en.wikipedia.org/w/load.php?lang=en&modules=site.styles&only=styles&skin=vector">
      </div>


      <div>
        <div v-html="this.displayedHtml"></div>
      </div>
    </div>
  </v-container>
</template>
<script>
import GameWikiPageExternalLinkDialog from "@/components/GameWikiPageExternalLinkDialog"

export default {
  name: 'GameWikiPage',
  components: {GameWikiPageExternalLinkDialog},
  props: ['displayedHtml', 'gamePushWikiPageLanguage'],
  data() {
    return {
      externalLink: "",
      isShowExternalLinkDialog: false,
    }
  },
  created: function () {
    window.fetchAndLoadNewWikiPage = this.fetchAndLoadNewWikiPage.bind(this);
    window.showExternalLinkDialog = this.showExternalLinkDialog.bind(this);
  },
  methods: {
    // TODO: Function can be used through webconsole to win 
    fetchAndLoadNewWikiPage(article) {
      article = article.substring(article.indexOf("#") + 1)
      this.$emit("requestNewHtml", article)
    },
    showExternalLinkDialog: function (externalLink) {
      this.externalLink = externalLink;
      this.isShowExternalLinkDialog = true;
    }
  },
  watch: {
    displayedHtml: function (val) {
      document.getElementById("main-container").scrollIntoView();
    }
  }
}
</script>
