<template>
  <v-container>

    <link rel="stylesheet"
          href="https://de.wikipedia.org/w/load.php?lang=de&amp;modules=ext.cite.styles%7Cext.flaggedRevs.basic%2Cicons%7Cext.uls.interlanguage%7Cext.visualEditor.desktopArticleTarget.noscript%7Cext.wikimediaBadges%7Cmediawiki.widgets.styles%7Coojs-ui-core.icons%2Cstyles%7Coojs-ui.styles.indicators%7Cskins.vector.styles.legacy%7Cwikibase.client.init&amp;only=styles&amp;skin=vector"/>
    <link rel="stylesheet"
          href="https://de.wikipedia.org/w/load.php?lang=de&amp;modules=site.styles&amp;only=styles&amp;skin=vector"/>
    <link rel="stylesheet"
          href="https://de.wikipedia.org/w/load.php?lang=de&modules=site.styles&only=styles&skin=vector">

    <v-overlay
        :z-index="zIndex"
        :value="overlay"
    >
      <div>
        🎉🎉🎉 FINISHED 🎉🎉🎉
      </div>
      <v-btn
          class="white--text"
          color="teal"
          @click="() => {this.overlay = false; goToFinishLobby()}"
      >
        {{ $t('show_results') }}
      </v-btn>
    </v-overlay>
    <div v-html="this.displayedHtml">
    </div>
  </v-container>
</template>
<script>
export default {
  name: 'GameWikiPage',
  data() {
    return {
      overlay: true,
      zIndex: 0,
    };
  },

  props: ['displayedHtml'],
  created: function () {
    window.myFunction = this.myFunction.bind(this);
  },
  methods: {
    myFunction(article) {
      article = article.substring(article.indexOf("#") + 1)
      this.$emit("requestNewHtml", article)

    },
    goToFinishLobby: function () {
      this.$emit("goToFinishLobby");
    }
  },
}
</script>
