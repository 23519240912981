<template>
  <v-container class="text-center">
      <v-row>
        <v-col></v-col>
        <v-col cols="3">
          <h1> {{ $t('home_main_title') }}</h1>
        </v-col>
        <v-col></v-col>
      </v-row>
      <v-row>
        <v-col></v-col>
        <v-col cols="5" style="text-align: left">
          <v-alert
              border="left"
              color="blue"
              elevation="6"
              text
              type="info"
          >
            <h3>Help us!</h3>
            <p>
              You have experience with <b>Vue.js</b>, <b>C#</b>, <b>SignalR</b>, <b>CSS</b>, <b>ElasticSearch</b>, <b>Google Cloud Platform</b> or similar?<br>
              Apply over our Discord Server <a href="https://discord.gg/W5RTMXytCM" target="_blank">here</a>!<br>
            </p>
          </v-alert>
          <v-alert
              border="left"
              color="orange"
              elevation="6"
              text
              type="error"
          >
            <h3>Dev Notes:</h3>
            <p>
              1. Use existing articles as long as autocomplete is not yet implemented.<br>
              -> (Copy the exact title of a wikipedia page).<br>
              2. Articles are case-sensitive.<br>
              3. Alternatively use the randomize button (<i class="mdi mdi-autorenew"></i>).<br>
              4. Report bugs <a href="https://github.com/DNX-Development/WikiRace-Issues/issues" target="_blank">here</a><br>
              5. Learn <a href="https://en.wikipedia.org/wiki/Wikiracing" target="_blank">here</a> and <a href="https://en.wikipedia.org/wiki/Wikipedia:Wikirace" target="_blank">here</a> what WikiRace is.<br>
              6. This game isn't optimized for mobile devices (yet)!<br>
              7. <b>Don't use in production yet!</b>.<br>
              8. Have fun :D<br>
            </p>
          </v-alert>
        </v-col>
        <v-col></v-col>
      </v-row>
      <v-row>
        <v-col></v-col>
        <v-col>
          <v-dialog
              v-model="dialog"
              width="500"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="red lighten-2"
                  dark
                  v-bind="attrs"
                  v-on="on"
              >
                {{ $t('home_create_game_button') }}
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="headline grey lighten-2">
                {{ $t('home_create_dialog_header_title') }}
              </v-card-title>

              <v-card-text>
                {{ $t('game_setting_join_behavior') }}
                <v-radio-group v-model="gameJoinBehavior">
                  <v-radio :label=" $t('game_setting_join_behavior_public_join')  " :value="'game_visible'"></v-radio>
                  <v-radio :label="$t('game_setting_join_behavior_link_join')" :value="'game_hidden'"></v-radio>
                </v-radio-group>

              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    @click="createGame"
                >
                  <!-- todo change text -->
                  Let's Go
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col></v-col>
      </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: 'Home',

  data: () => ({
    dialog: false,
    gameJoinBehavior: "game_visible",
    gamePassword: "",
  }),
  methods: {
    createGame: function () {
      let vm = this;
      /* eslint-disable no-console */

      console.log("lang: " + this.$i18n.locale)

      axios.post('/game/create', {
        "GameJoinBehavior": this.gameJoinBehavior,
        "GameLanguageStr": this.$i18n.locale
      })
          .then(function (response) {
            console.log(response);
            let game = response["data"]
            vm.$cookies.set("game", game, 60 * 60 * 6)
            vm.$router.push("/game")
          })
          .catch(function (error) {
            console.log(error);

          });

      /* eslint-enable no-console */

    }
  }
}
</script>
