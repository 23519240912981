<template>
  <country-flag :country="renderFlag(languageAbbreviation)"/>
</template>

<script>
export default {
  name: "LanguageFlag.vue",
  props: ['languageAbbreviation'],
  methods: {
    renderFlag: function (lang) {
      if (lang === "en") {
        return "gb";
      } else {
        return lang;
      }
    }
  }
}
</script>

<style scoped>

</style>