<template>
  <v-container>
    <link rel="stylesheet"
          href="https://de.wikipedia.org/w/load.php?lang=de&amp;modules=ext.cite.styles%7Cext.flaggedRevs.basic%2Cicons%7Cext.uls.interlanguage%7Cext.visualEditor.desktopArticleTarget.noscript%7Cext.wikimediaBadges%7Cmediawiki.widgets.styles%7Coojs-ui-core.icons%2Cstyles%7Coojs-ui.styles.indicators%7Cskins.vector.styles.legacy%7Cwikibase.client.init&amp;only=styles&amp;skin=vector"/>
    <link rel="stylesheet"
          href="https://de.wikipedia.org/w/load.php?lang=de&amp;modules=site.styles&amp;only=styles&amp;skin=vector"/>
    <link rel="stylesheet"
          href="https://de.wikipedia.org/w/load.php?lang=de&modules=site.styles&only=styles&skin=vector">

    <div v-html="response">
    </div>
  </v-container>
</template>
<script>
import {HubConnectionBuilder} from "@microsoft/signalr";

export default {
  name: 'WikiPage',

  data() {
    return {
      connection: null,
      response: ""
    }
  },
  created: function () {
    window.myFunction = this.myFunction.bind(this);
    this.connection = new HubConnectionBuilder()
        .withUrl("/game/play")
        .withAutomaticReconnect([0, 1000, 5000, null])
        .build();

    this.connection.start()
        .then(() => this.connection.invoke("getSpecificWikiPage", "de", "Käse"));

    this.connection.on("rec", resp => {
      let r = resp;
      this.response = r;
    });

  },
  methods: {
    isCurrentLocale: function (lang) {
      let glob = this.$i18n.locale.toString();
      return lang.toString() === glob
    },
    renderFlag: function (lang) {
      if (lang === "en") {
        return "gb";
      } else {
        return lang;
      }
    },
    myFunction(article) {
      /* eslint-disable no-console */
      article = article.substring(article.indexOf("#")+1)
      console.log("article: " + article)
      /* eslint-enable no-console */

      this.connection.invoke("getSpecificWikiPage", "de", article)
    }
  },
}
</script>
