import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import CountryFlag from 'vue-country-flag'
import i18n from './i18n'
import router from './router'
import VueCookies from 'vue-cookies'
import VueClipboard from 'vue-clipboard2'
import VueMatomo from 'vue-matomo'

Vue.config.productionTip = false
Vue.component('country-flag', CountryFlag)
Vue.use(VueCookies)
Vue.use(VueClipboard)
Vue.use(VueMatomo, {
    host: "https://analytics.deeonix.de",
    siteId: 1,
    trackerFileName: 'matomo',
    router: router,
    enableLinkTracking: true,
    requireConsent: false,
    trackInitialView: true,
    disableCookies: false,
    enableHeartBeatTimer: false,
    heartBeatTimerInterval: 15,
    debug: false,
    userId: undefined,
    cookieDomain: undefined,
    domains: undefined,
    preInitActions: []
})

new Vue({
    vuetify,
    i18n,
    VueCookies,
    router,
    render: h => h(App)
}).$mount('#app')
