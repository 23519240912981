<template>
  <v-app>

    <v-app-bar
        app
        color="primary"
        dark
    >
      <div class="d-flex align-center">
        <v-img
            alt="WikiRace Logo"
            class="shrink mr-2"
            contain
            src="@/assets/logo.svg"
            transition="scale-transition"
            width="55"
        />

        <div class="text-h4 font-weight-bold">WikiRace</div>
        <!--<v-img
            alt="Vuetify Name"
            class="shrink mt-1 hidden-sm-and-down"
            contain
            min-width="100"
            src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
            width="100"
        />-->
      </div>
      <v-spacer></v-spacer>
      <div v-if="targetPage" class="text-h6">🏁 {{ targetPage }} 🏁</div>
      <v-spacer></v-spacer>
      <locale-changer/>

      <v-btn
          :href="'https://github.com/DNX-Development/WikiRace/commit/' + getGitLong"
          target="_blank"
          text
      >

        <span class="mr-2">Version {{ getGitShort }}</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>


    <v-main id="main-container">
      <!-- <v-main style="padding-top: 50px">-->

      <!--      TODO: implement state globally so that can be activated and deactivated -->
      <v-progress-linear
          v-if="showLoadingAnimation"
          indeterminate
          color="yellow darken-2"
      ></v-progress-linear>
      <router-view>
      </router-view>
    </v-main>
  </v-app>
</template>

<script>
import LocaleChanger from "@/components/LocaleChanger";


export default {
  name: 'App',

  components: {
    LocaleChanger
  },
  mounted() {
    window.addEventListener("keydown", function (e) {
      // eslint-disable-next-line no-console
      console.log(e.key)
      if ((e.ctrlKey && (e.code === "KeyF" || e.code === "KeyG")) || e.metaKey || e.code === "F3") {
        e.preventDefault();
      }

    })
  },
  data: () => ({
    showLoadingAnimation: false,
    getGitLong: process.env.VUE_APP_GIT_LONG,
    getGitShort: process.env.VUE_APP_GIT_SHORT,
    targetPage: "",
  }),
};
</script>