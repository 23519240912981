<template>
  <v-container>
    <v-dialog
        transition="dialog-top-transition"
        max-width="600"
        persistent
        v-model="dialog"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar
              color="primary"
              dark
          >enter username
          </v-toolbar>
          <v-card-text>
            <v-text-field v-model="userName"
                          :append-icon="'mdi-autorenew'"
                          @click:append="requestNewRandomUserName">
            </v-text-field>
          </v-card-text>
          <v-card-actions class="justify-end">
            {{ userNameInputState }}
            <v-btn
                text
                :disabled="blocked || userName == null || userName.length < 3 || userName.length > 22"
                @click="main()"
            >sumbit
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import _ from "lodash";

export default {
  name: "UserNameInput",
  created: function () {
    this.getUserName();
    this.debCheckDuplicateUserName = _.debounce(this.checkDuplicateUserName, 500);
    this.gameId = this.$cookies.get("game");

  },
  data() {
    return {
      userNameInputState: "",
      dialog: true,
      userName: "",
      blocked: true,
      gameId: ""
    }
  },
  props: {},
  methods: {
    requestNewRandomUserName: function () {
      let vm = this
      axios.get("/game/generate/username").then(function (response) {
        vm.userName = response.data
      })
    },
    getUserName: function () {
      let vm = this;
      if (vm.$cookies.isKey("username")) {
        vm.userName = vm.$cookies.get("username")
      } else {
        this.requestNewRandomUserName()
      }
    },
    checkDuplicateUserName: function () {
      this.userNameInputState = 'waiting...'
      let vm = this
      axios.post("/game/check/username?gameid=" + this.gameId + "&username=" + this.userName.trim())
          .then(function (response) {
            vm.blocked = response.data;
            if (vm.blocked) {
              vm.userNameInputState = "username is already in use...."
            } else {
              vm.userNameInputState = ""
            }

          })
          .catch(function (error) {
            vm.userNameInputState = 'Error! Could not reach the API. ' + error
          })
    },
    main: function () {
      this.$cookies.set("username", this.userName.trim())
      this.dialog = false;
      this.$emit("userNameFinished", this.userName.trim())
    }
  },
  watch: {
    userName: function () {
      this.userNameInputState = ""
      this.debCheckDuplicateUserName()
    },
  }
}
</script>