import Vue from 'vue'
import VueRouter from 'vue-router'
import CreateGame from "@/components/CreateGame";
import Home from "@/components/Home/";
import WikiPage from "@/components/WikiPage";
import GameContainer from "@/components/GameContainer";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        // Deprecated?
        path: '/create',
        name: 'CreateGame',
        component: CreateGame
    },
    {
        path: '/test',
        name: 'WikiPage',
        component: WikiPage
    },
    {
        path: '/game',
        name: 'GameContainer',
        component: GameContainer
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
