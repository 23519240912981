<template>
  <div>
    <div>
      <v-dialog
          transition="dialog-top-transition"
          max-width="600"
          @click:outside="() => {this.dialog = false; this.$emit('update:dialog', false)}"
          v-model="dialog"
      >
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar
                color="primary"
                dark
            >
              <v-icon
                  dark
                  right
                  class="mr-2"
              >
                mdi-alert
              </v-icon>
              <v-toolbar-title class="text-h5">
                {{ $t('game_wiki_page_external_content_dialog_title') }}
              </v-toolbar-title>

            </v-toolbar>
            <v-card-text>
              <div class="pa-12">
                {{ $t('game_wiki_page_external_content_dialog_info_text') }}
                <div>
                  <code dark> {{ externalLink }}</code>
                </div>
                {{ $t('game_wiki_page_external_content_dialog_focus_shift_warning') }}

              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                  text
                  @click="openInNewTabAndCloseDialog"
              > {{ $t('game_wiki_page_external_content_dialog_button_open_page') }}

              </v-btn>
              <v-btn
                  text
                  @click="onlyCloseDialog"
              >{{ $t('game_wiki_page_external_content_dialog_button_close') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: "GameWikiPageExternalLinkDialog.vue",
  props: {
    externalLink: {
      default: ""
    },
    dialog: {
      default: false
    }
  },
  mounted() {
    this.showDialog = true
  },
  data() {
    return {
      showDialog: true
    }
  },
  methods: {
    openInNewTabAndCloseDialog: function () {
      window.open(this.externalLink, '_blank');
      window.focus();
      this.$emit('update:dialog', false)
    },
    onlyCloseDialog: function () {
      this.$emit('update:dialog', false)
    }
  }
}
</script>
