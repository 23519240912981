<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          {{ $t('create_game_title') }}
        </h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col>

        {{ $t('create_game_language_setting') }}
        <v-radio-group v-model="chekedLanguage">
          <v-radio v-for="lang in langs"
                   :key="lang"
                   :value="lang">
            <template v-slot:label>
              <country-flag :country="renderFlag(lang)"/>
              {{ lang }}
            </template>
          </v-radio>
        </v-radio-group>

        {{ $t('start_wiki_article') }}
        <v-text-field
            :append-icon="'mdi-autorenew'"
            @click:append="() => { this.connection.invoke('gameNewRandomWikiPage', this.chekedLanguage, 'gameNewRandomStartPage') }"
            v-model="gameStartPage"
        >
        </v-text-field>

        {{ $t('target_wiki_article') }}
        <v-text-field
            v-model="gameTargetPage"
            :append-icon="'mdi-autorenew'"
            @click:append="() => { this.connection.invoke('gameNewRandomWikiPage', this.chekedLanguage, 'gameNewRandomTargetPage') }">
        </v-text-field>

        {{ $t('game_mode_setting_title') }}
        <v-radio-group v-model="gameMode">
          <v-radio :label=" $t('game_mode_time')  " :value="'time'"></v-radio>
          <v-radio :label="$t('game_mode_clicks')" :value="'clicks'"></v-radio>
        </v-radio-group>

        {{ $t('game_setting_join_behavior') }}
        <v-radio-group v-model="gameJoinBehavior">
          <v-radio :label=" $t('game_setting_join_behavior_public_join')  " :value="'publicJoin'"></v-radio>
          <v-radio :label="$t('game_setting_join_behavior_link_join')" :value="'linkJoin'"></v-radio>
          <v-radio :label="$t('game_setting_join_behavior_passwd_join')" :value="'passwordJoin'"></v-radio>
        </v-radio-group>

        <div v-show='showPasswordField'>
          {{ $t('game_setting_join_behavior_password') }}

          <v-text-field :label=" $t('game_setting_password_field')">
          </v-text-field>

        </div>

        <v-btn @click="fetchRandomWikiPageSend()"></v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {HubConnectionBuilder} from "@microsoft/signalr";

export default {
  name: 'CreateGame',

  data() {
    return {
      langs: ['en', 'de'],
      chekedLanguage: this.$i18n.locale,
      gameMode: "time",
      gameJoinBehavior: "public",
      gameJoinPassword: "",
      connection: null,
      gameStartPage: "",
      gameTargetPage: ""
    }
  },
  created: function () {
    this.connection = new HubConnectionBuilder()
        .withUrl("/game/play")
        .withAutomaticReconnect([0, 1000, 5000, null])
        .build();

    this.connection.on("randomWikiPageReceive", function (resp) {
      // eslint-disable-next-line no-console
      console.log("KEKW: " + resp);
    });

    this.connection.on("gameNewRandomStartPage", resp => {
      let k = "" + resp;
      this.gameStartPage = k;
      // eslint-disable-next-line no-console
      console.log(k);
    });
    this.connection.on("gameNewRandomTargetPage", resp => {
      let k = "" + resp;
      this.gameTargetPage = k;
      // eslint-disable-next-line no-console
      console.log(k);
    });

    this.connection.on("send", data => {
      // eslint-disable-next-line no-console
      console.log(data);
    });


    this.connection.start()
        .then(() => this.connection.invoke("send", "Hello"));

  },
  computed: {
    showPasswordField: function () {
      /* eslint-disable no-console */
      console.log("selected: " + this.gameJoinBehavior);
      /* eslint-enable no-console */
      return this.gameJoinBehavior === "passwordJoin"
    }
  },
  methods: {
    isCurrentLocale: function (lang) {
      let glob = this.$i18n.locale.toString();
      return lang.toString() === glob
    },
    renderFlag: function (lang) {
      if (lang === "en") {
        return "gb";
      } else {
        return lang;
      }
    },
    fetchRandomWikiPageSend: function () {
      /* eslint-disable no-console */

      this.connection.invoke("RandomWikiPage", this.chekedLanguage)

      /* eslint-enable no-console */
    }
  },
  sockets: {

    testEvent(data) {
      /* eslint-disable no-console */
      console.log("EVENT: " + data);
      /* eslint-enable no-console */
    }


  }
}
</script>