<template>
  <div>
    <div>
      <li v-for="member in responseData" :key="member.Username">
        <GameFinishLobbyMemberCard :member="member"></GameFinishLobbyMemberCard>
      </li>
    </div>
    <v-btn @click="sendBackToLobby" :disabled="goBackToLobbyChecked">back to lobby</v-btn>
  </div>
</template>

<script>
import GameFinishLobbyMemberCard from "@/components/GameFinishLobbyMemberCard";

export default {
  name: "GameFinishLobby",
  components: {
    GameFinishLobbyMemberCard
  },
  data() {
    return {
      connection: this.$parent.connection,
      responseData: null,
      goBackToLobbyChecked: false
    }
  },
  mounted() {
    let vm = this;
    this.connection.on("pushGameFinishUserList", response => {
      let members = JSON.parse(response);
      members = members.sort((a, b) => a.FinishPosition - b.FinishPosition)
      vm.responseData = members;
    });
  },
  methods: {
    sendBackToLobby: function () {
      this.goBackToLobbyChecked = true;
      this.connection.invoke("GoBackToLobbyRequest");
    }
  }
}
</script>
<style scoped type="text/css">
li {
  list-style-type: none;
}
</style>