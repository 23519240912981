<template>
  <div>
    <v-row>
      <v-col cols="3"></v-col>
      <v-col>
        <v-card
            class="mx-auto"
            max-width="650"
        >
          <v-row>
            <v-col>
              <v-card-title>
                {{ member.Username }}
              </v-card-title>
    
              <v-card-subtitle>
                <div>State: {{ member.StateOfUser }}</div>
                <div>Clicks: {{ member.ClickCounter - 1 }}</div>
                <div>Time: {{ member.SecondCounter }}</div>
                <div>pos: {{ member.FinishPosition }}</div>
              </v-card-subtitle>
            </v-col>
            <v-col cols="2">
              <div v-if="member.FinishPosition < 4" class="rank-badge" :data-rank="member.FinishPosition"></div>
              <div v-else class="rank-text">{{ member.FinishPosition }}.</div>
            </v-col>
          </v-row>
    
          <v-card-actions>
            <v-spacer></v-spacer>
    
            <v-btn
                icon
                @click="show = !show"
            >
              <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </v-card-actions>
    
          <v-expand-transition>
            <div v-show="show">
              <v-divider></v-divider>
    
              <v-card-text>
    
                <v-timeline
                    align-top
                    dense
                >
                  <v-timeline-item
                      v-for="event in member.VisitedPages"
                      :key="event[0]"
                      small
                  >
                    <div>
                      <div class="font-weight-normal">
                        <strong>{{ event["Item2"] }}</strong>
                        <p>Nach insgesamt {{ event["Item3"] }} Sekunden wechsel</p>
                      </div>
                    </div>
                  </v-timeline-item>
                </v-timeline>
    
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
      <v-col cols="3"></v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "GameFinishLobbyMemberCard.vue",
  props: ["member"],
  data: () => ({
    show: false,
  }),
}
</script>
User: {{ member.Username }} -> State: {{ member.StateOfUser }} -> Clicks: {{ member.ClickCounter }} -> list: {{ member.VisitedPages }}
<style scoped type="text/css">
.rank-text {
  text-align: center;
}
.rank-badge {
  height: 50px;
  background-repeat: no-repeat;
  background-position: center;
}
.rank-badge[data-rank="1"] {
  background-image: url("../assets/rank1.svg");
}
.rank-badge[data-rank="2"] {
  background-image: url("../assets/rank2.svg");
}
.rank-badge[data-rank="3"] {
  background-image: url("../assets/rank3.svg");
}
</style>