<template>
  <div>
    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout">{{ snackbarText }}</v-snackbar>
    <v-row>
      <v-col cols="4">
        <v-row>
          <v-col cols="6">
            {{ $t('create_game_language_setting') }}
            <v-radio-group v-model="gameLanguage" @change="sendNewLanguageToServer(gameLanguage)"
                           :disabled="lobbyReady">
              <v-radio v-for="lang in langs"
                       :key="lang"
                       :value="lang"
              >
                <template v-slot:label>
                  <LanguageFlag :languageAbbreviation="lang"></LanguageFlag>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            {{ $t('start_wiki_article') }}
            <v-text-field
                :append-icon="'mdi-autorenew'"
                @click:append="() => { this.connection.invoke('gameNewRandomWikiPage', 'setNewStartPage') }"
                v-model="gameStartPage"
                :disabled="lobbyReady || (userName !== gameHost)"
            >
            </v-text-field>

            {{ $t('target_wiki_article') }}
            <v-text-field
                v-model="gameTargetPage"
                :append-icon="'mdi-autorenew'"
                :disabled="lobbyReady"
                @click:append="() => { this.connection.invoke('gameNewRandomWikiPage', 'setNewTargetPage') }">
            </v-text-field>

            {{ $t('game_mode_setting_title') }}
            <v-radio-group v-model="gameMode" @change="gameModeBroadcast()" :disabled="lobbyReady">
              <v-radio :label=" $t('game_mode_time')  " :value="'time'"></v-radio>
              <v-radio :label="$t('game_mode_clicks')" :value="'clicks'"></v-radio>
            </v-radio-group>

            <v-checkbox
                v-model="gameReady"
                label="Ready?"
                @change="() => { this.connection.invoke('SetReady', this.gameReady) }"
            ></v-checkbox>
            Lobby ready {{ lobbyReady }}

            <v-btn @click="startGame" :disabled="!lobbyReady">START</v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col></v-col>
      <v-col cols="3">
        <v-card class="playerlist" shaped>
          <v-card-title class="playerlist-title">{{ $t('playerlist') }}</v-card-title>
          <v-card-text>
            <v-list>
              <v-list-item class="playerlist-username" v-for="client in clients" :key="client.Username">
                <v-list-item-content>
                  <v-list-item-title v-text="client.Username + ' ' + client.UsernameAppends"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
        <v-btn id="inviteBtn" @click="copyJoinLink">
          <v-icon>mdi-account-arrow-left</v-icon>
          <span>INVITE</span>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import GameCountdown from "@/components/GameCountdown";
import GameWikiPage from "@/components/GameWikiPage";
import _ from 'lodash';
import LanguageFlag from "@/components/LanguageFlag"

export default {
  name: "GameLobby",
  components: {LanguageFlag},
  data() {
    return {
      host: location.host,
      userName: this.$parent.userName,
      langs: ['en', 'de'],
      gameId: this.$parent.gameId,
      clients: null,
      dialog: true,
      blocked: true,
      userNameInputState: "",
      snackbar: false,
      snackbarText: "",
      snackbarTimeout: 2000,
      gameLanguage: "",
      gameStartPage: "",
      gameTargetPage: "",
      gameMode: "time",
      gameHost: "",
      gameReady: false,
      lobbyReady: false,
      lobbyActive: true,
      gameActive: false,
      currentComponent: null,
      connection: this.$parent.connection,
      displayedHtml: null,
      rules: {
        required: v => !!v || 'this field is required',
      }
    }
  },
  created: function () {
    let vm = this;

    this.connection.on("showClients", resp => {
      /* eslint-disable no-console */
      let members = JSON.parse(resp)
      vm.clients = members
      console.log("clients: " + members.toString())
      /* eslint-enable no-console */

    });

    this.connection.on("setLanguage", resp => {
      /* eslint-disable no-console */
      console.log("srv lang: " + resp);
      /* eslint-enable no-console */
      vm.gameLanguage = resp;
      vm.$parent.gameLanguage = resp;
    });

    this.connection.on("setNewStartPage", resp => {
      let k = "" + resp;
      this.gameStartPage = k;
      // eslint-disable-next-line no-console
      console.log(k);
    });

    this.connection.on("setNewTargetPage", resp => {
      let k = "" + resp;
      this.gameTargetPage = k;
      // TODO: Use alternative to set targetPage in 'App'
      // eslint-disable-next-line no-console
      console.log(k);
    });

    this.connection.on("setNewGameMode", resp => {
      this.gameMode = resp;
    });

    this.connection.on("setGameHost", resp => {
      this.gameHost = resp;
    });

    this.connection.on("readyStatus", resp => {
      this.lobbyReady = resp;
    });

    this.connection.on("startCountdown", resp => {
      this.lobbyReady = GameCountdown;
    });

    this.debStartPageBroadcast = _.debounce(this.gameStartPageBroadcast, 500)
    this.debTargetPageBroadcast = _.debounce(this.gameTargetPageBroadcast, 500)

  },
  methods: {
    sendNewLanguageToServer: function (langSel) {
      this.connection.invoke("UpdateLanguage", langSel)
    },
    gameStartPageBroadcast: function () {
      if (this.connection != null) {
        this.connection.invoke("GameNewStartPage", this.gameStartPage)
      }
    },
    gameTargetPageBroadcast: function () {
      this.$parent.$parent.$parent.$parent.targetPage = this.gameTargetPage;
      this.connection.invoke("GameNewTargetPage", this.gameTargetPage)
    },
    gameModeBroadcast: function () {
      this.connection.invoke("GameModeBroadcast", this.gameMode)
    },
    startGame: function () {
      this.lobbyReady = false;
      this.connection.invoke("BroadcastStartGameProcedure")
    },
    copyJoinLink: function () {
      this.$copyText('http://' + this.host + '/game/join/' + this.gameId)
      this.snackbarText = "Copied!"
      this.snackbarTimeout = 1000
      this.snackbar = true
    }
  },
  watch: {
    gameStartPage: function () {
      this.debStartPageBroadcast()
    },
    gameTargetPage: function () {
      this.debTargetPageBroadcast()
    },
  }
}
</script>

<style>
.playerlist {
  margin-bottom: 10px;
}

.playerlist-title {
  color: #ffffff;
  background-color: #7209b7;
}

.playerlist-username {
  font-size: 1.0rem;
  font-weight: 500;
  min-height: 22px;
  height: 22px;
}
</style>
