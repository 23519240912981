<template>
  <v-container>
    <div
        @userNameFinished="startLobbyProcedure"
        @requestNewHtml="requestNewHtml"
        @gameDisplayWikiPage="startWikiPageRaceProcedure"
        @goToFinishLobby="goToFinishLobby"
        :displayedHtml="gameDisplayedHtml"
        :is="currentComponent"
        :gamePushWikiPageLanguage="gamePushWikiPageLanguage"
    ></div>
  </v-container>
</template>

<script>
import GameWikiPageFinished from "@/components/GameWikiPageFinished";
import GameCountdown from "@/components/GameCountdown";
import UserNameInput from "@/components/UserNameInput";
import {HubConnectionBuilder} from "@microsoft/signalr";
import GameWikiPage from "@/components/GameWikiPage";
import GameLobby from "@/components/GameLobby";
import GameFinishLobby from "@/components/GameFinishLobby";
import axios from "axios";

export default {
  name: 'GameContainer',

  data: () => ({
    userName: "",
    gameId: null,
    currentComponent: null,
    gameDisplayedHtml: null,
    gamePushWikiPageLanguage: ""
  }),

  methods: {
    requestNewHtml: function (articleName) {
      let vm = this;
      vm.connection.invoke("getSpecificWikiPageInGame", articleName);
    },
    startWikiPageRaceProcedure: function () {
      let vm = this;
      vm.currentComponent = GameWikiPage;
    },
    startLobbyProcedure: function (username) {
      let vm = this;

      vm.userName = username;
      vm.$cookies.set("username", vm.userName);

      vm.connection = new HubConnectionBuilder()
          .withUrl("/game/play")
          .withAutomaticReconnect([0, 1000, 5000, null])
          .build();

      vm.connection.on("gamePushHtml", response => {
        vm.gameDisplayedHtml = response.toString();
      });

      vm.connection.on("gamePushFinish", response => {
        vm.currentComponent = GameWikiPageFinished;
      });

      vm.connection.on("gameStartTimeAndHereAfterDisplayWikiPage", response => {
        vm.currentComponent = GameCountdown;
      });

      vm.connection.on("pushGameLobbyComponent", response => {
        vm.currentComponent = GameLobby;
      });

      vm.connection.on("gamePushVisitedPages", response => {
        // eslint-disable-next-line no-console
        console.log("visited pages: " + response);
      });

      vm.connection.on("gamePushWikiPageLanguage", response => {
        // eslint-disable-next-line no-console
        console.log("wikipage lang:" + response);
        this.gamePushWikiPageLanguage = response;
      });

      vm.connection.start()
          .then(() => vm.connection.invoke("RegisterClient"));

      vm.connection.onclose(function (error) {
        if (error.message.includes("1006")) {
          // TODO Snackbar
        }
        /* eslint-disable no-console */
        console.warn(error.message)
        /* eslint-enable no-console */
      });

      vm.currentComponent = GameLobby;

    },
    startValidateGameAndStartJoinProcedure: function () {
      let vm = this;

      if (!vm.$cookies.isKey("game")) vm.$router.push("/");
      vm.gameId = vm.$cookies.get("game");

      let gameValidationRequestUrl = "/game/check/" + vm.gameId;
      axios.post(gameValidationRequestUrl).then(function (response) {
        let gameIsValid = response.data;
        if (!gameIsValid) vm.$router.push("/");
        vm.currentComponent = UserNameInput;
      });
    },
    goToFinishLobby: function () {
      let vm = this;
      vm.currentComponent = GameFinishLobby;
      vm.connection.invoke("pushDataToFinishLobby");
    }
  },
  created: function () {
    let vm = this;
    vm.startValidateGameAndStartJoinProcedure();
  },
}
</script>
